import React, { useContext } from 'react';
import { MenuItem, FormControl, Menu, Button, Box, Avatar } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import avatarIcons from '../utils/avatarIcons.js';

import { SuperMainContentContext } from '../contexts/SuperMainContext.jsx';
import { AppContext } from '../contexts/AppContext.jsx'
import { useTheme } from '@mui/material/styles';

const longestAvatarName = avatarIcons.reduce((longest, current) => {
  return current.name.length > longest.length ? current.name : longest;
}, '');

const AvatarSelector = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { avatarIcon, setAvatarIcon } = useContext(SuperMainContentContext);
  const { setIsFocused, } = useContext(AppContext);
  const theme = useTheme();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setIsFocused(true);
  };

  const handleAvatarChange = (newIcon) => {
    setAvatarIcon(newIcon.icon);
    handleClose();
  };

  const selectedAvatar = avatarIcons.find(item => item.icon === avatarIcon) || avatarIcons[0];

  return (
    <FormControl variant="outlined" size="small"
      sx={{
        '&:hover': {
          backgroundColor: theme.palette.action.hover,
          borderRadius: 2
        } 
      }}
    >
      <Button
        onClick={handleClick}
        endIcon={<ArrowDropDownIcon />}
        startIcon={
          <Avatar sx={{
            width: 24,
            height: 24,
            bgcolor: 'rgba(0, 0, 0, 0.1)', // Light grey background
            color: 'text.primary' // Use the primary text color for the icon
          }}>
            <selectedAvatar.icon />
          </Avatar>
        }
  sx={{
    minWidth: `${longestAvatarName.length * 14}px`,
    justifyContent: 'flex-start',
    px: 2,
    // New styles to position content and arrow
    '& .MuiButton-endIcon': {
      ml: 'auto', // Push icon to the right
      position: 'absolute',
      right: 8,
    },
    '& .MuiButton-startIcon': {
      mr: 1, // Space between icon and text
    },
    pr: 4, // Make room for the arrow on the right
  }}
      >
        {selectedAvatar.name}
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {avatarIcons.map((avatar, index) => (
          <MenuItem key={index} onClick={() => handleAvatarChange(avatar)}>
            <Box display="flex" alignItems="center">
              <Avatar sx={{
                width: 24,
                height: 24,
                bgcolor: 'rgba(0, 0, 0, 0.1)',
                color: 'text.primary',
                mr: '1em'
              }}>
                <avatar.icon />
              </Avatar>
              {avatar.name}
            </Box>
          </MenuItem>
        ))}
      </Menu>
    </FormControl>
  );

};

export default AvatarSelector;