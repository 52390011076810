import React from 'react';
import { MemoizedLetter } from './Letter';


const Word = ({
  word,
  startIndex,
  currentIndex,
  userInput,
  setCorrectness
}) => {
  return (
    <span
      style={{
        display: 'inline-block',
        whiteSpace: 'pre',
      }}>
      {word.split('').map((letter, index) => {
        const absoluteIndex = startIndex + index;
        let correctness = null;
        let isCurrent = false;

        if (absoluteIndex === currentIndex) {
          isCurrent = true;
        } else if (absoluteIndex < currentIndex) {
          correctness = setCorrectness(absoluteIndex);
        }

        return (
          <MemoizedLetter
            key={index}
            letter={letter}
            isSpace={letter === ' '}
            isCurrent={isCurrent}
            correctness={correctness}
            index={absoluteIndex}
          />
        );
      })}
    </span>
  );
};

const MemoizedWord = React.memo(Word);

export { Word, MemoizedWord };