import React from 'react';
import ScrollingDisplay from './ScrollingDisplay';
import SlidingDisplay from './SlidingDisplay';
import StaticDisplay from './StaticDisplay';
import { Caret } from '../../Caret';

export const DisplayMode = {
  STATIC: 'static', 
  SCROLLING: 'scrolling',
  SLIDING: 'sliding'
};

const TypingDisplay = ({
  displayMode,
  children,
  currentIndex,
  containerRef,
  isFocused,
  caretProps,
  selectedLanguage,
  isLoading
}) => {
  const commonProps = {
    containerRef,
    isFocused,
    caretProps,
    currentIndex,
    selectedLanguage,
    isLoading
  };

  // console.log("TypingDisplay rendered");

  switch (displayMode) {
    case DisplayMode.SCROLLING:
      return (
        <ScrollingDisplay {...commonProps} >
          {children}
          <Caret {...caretProps} />
        </ScrollingDisplay>
      );
    case DisplayMode.SLIDING:
      return (
        <SlidingDisplay {...commonProps}>
          {children}
        </SlidingDisplay>
      );
    default:
      return (
        <StaticDisplay {...commonProps} >
          {children}
          <Caret {...caretProps} />
        </StaticDisplay>
      );
  }
};

export default TypingDisplay;