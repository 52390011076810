import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { isRTL } from '../../TypingTest';
import { SlidingCaret } from '../../../components/SlidingCaret';

const SlidingDisplay = ({ children, containerRef, currentIndex, isFocused, caretProps, selectedLanguage, isLoading }) => {
  const { position, shouldBlink } = caretProps;
  const [translateX, setTranslateX] = useState(0);

  useEffect(() => {
    const letterWidth = containerRef.current?.querySelector('[data-index="0"]')?.offsetWidth || 0;
    const initialOffset = containerRef.current?.offsetWidth / 2 - letterWidth / 2;
    setTranslateX(initialOffset - currentIndex * letterWidth);
  }, [currentIndex]);

  return (
    <Box sx={{ position: 'relative', overflow: 'hidden', width: '100%' }}>
      <Box
        ref={containerRef}
        sx={{
          display: 'flex',
          transform: `translateX(${translateX}px)`,
          transition: 'transform 0.1s ease-out',
          direction: isRTL(selectedLanguage) ? 'rtl' : 'ltr',
          visibility: isLoading ? 'hidden' : 'visible'
        }}
      >
        {children}
      </Box>
      <SlidingCaret position={position} shouldBlink={shouldBlink} />
    </Box>
  );
};

export default React.memo(SlidingDisplay);