import React, { useState, useContext, useEffect, useRef } from 'react';
import {
  Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText,
  IconButton, AppBar, Toolbar, Typography, Button, Dialog, DialogTitle,
  DialogContent, DialogActions, CircularProgress, Box
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';

import InfoIcon from '@mui/icons-material/Info';
import EmailIcon from '@mui/icons-material/Email';
import { IoMdDocument } from "react-icons/io";
import { RiPresentationFill } from "react-icons/ri";

import { useTour } from '@reactour/tour'
import { PaletteSelector } from './PaletteSelector';
import { VideoLibrary } from './VideoLibrary';
import FontSelector from './FontSelector';
import AvatarSelector from './AvatarSelector';
import RoadMap from './RoadMap';
import PayPalSubscriptionButton from './PayPalSubscriptionButton';
import { ContactDialog } from './Header';
import { AuthButton } from './AuthButtonFirebase';
import { AppContext } from '../contexts/AppContext';
import { SuperMainContentContext } from '../contexts/SuperMainContext';
import { getUserSettings, updateUserTourStatus } from '../utils/firestoreUtils';
import { HomeButtonWithLogo } from './HomeButtonWithLogo.jsx';

const SidebarMenu = () => {
  const [roadMapOpen, setRoadmapOpen] = useState(false);
  const [paymentDialogOpen, setPaymentDialogOpen] = useState(false);
  const [contactOpen, setContactOpen] = useState(false);
  const {
    currUser,
    isUserLoading,
    setIsFocused,
    isSidebarOpen,
    setIsSidebarOpen,
    isWebsiteReady,
    showNDA
  } = useContext(AppContext);
  const {
    setStatisticsAccordionExpanded,
    setPatternsAccordionExpanded
  } = useContext(SuperMainContentContext);
  const { setIsOpen } = useTour();
  const theme = useTheme();
  const navigate = useNavigate();
  const mountedRef = useRef(false);
  const tourInitializedRef = useRef(false);

  useEffect(() => {
    const checkAndShowTour = async () => {
      // Only run this effect once during mount
      if (mountedRef.current) return;
      mountedRef.current = true;

      const browserFirstVisit = !localStorage.getItem('notFirstVisit');
      console.log('Initial tour check:', {
        browserFirstVisit,
        currUser,
        isUserLoading,
        isWebsiteReady,
        showNDA,
        tourInitialized: tourInitializedRef.current
      });

      if (currUser && !isUserLoading && isWebsiteReady) {
        try {
          const userSettings = await getUserSettings(currUser.uid);
          if (!userSettings.hasSeenTour) {
            console.log('Showing tour for logged in user');
            setIsOpen(true);
            await updateUserTourStatus(currUser.uid, true);
            tourInitializedRef.current = true;
          }
        } catch (error) {
          console.error('Error checking tour status:', error);
        }
      } else if (browserFirstVisit && !showNDA && !tourInitializedRef.current) {
        console.log('Showing tour for first time visitor');
        setIsOpen(true);
        // Only set localStorage after confirming tour is opened
        setIsOpen((isOpen) => {
          if (isOpen) {
            localStorage.setItem('notFirstVisit', 'true');
            tourInitializedRef.current = true;
          }
          return isOpen;
        });
      }
    };

    if (isWebsiteReady) {
      checkAndShowTour();
    }
  }, [currUser, isUserLoading, isWebsiteReady, showNDA, setIsOpen]);

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setIsSidebarOpen(open);
  };

  const navigateToRoot = () => {
    navigate('/');
    setIsFocused(true);
    setIsSidebarOpen(false);
  };

  const handleClickOpenToSRoute = () => {
    navigate('/privacy-policy-and-terms-of-service');
    setIsSidebarOpen(false);
  };

  const navigateToAppInfo = () => {
    navigate('/info');
    setIsSidebarOpen(false);
  };

  const handleOpenRoadmap = () => {
    setRoadmapOpen(true);
    setIsSidebarOpen(false);
  };

  const handleCloseRoadmap = () => {
    setRoadmapOpen(false);
  };

  const handleOpenContact = () => {
    setContactOpen(true);
    setIsSidebarOpen(false);
  };

  const handleCloseContact = () => {
    setContactOpen(false);
  };

  const handleOpenPaymentDialog = () => {
    setPaymentDialogOpen(true);
    setIsSidebarOpen(false);
  };

  const handleClosePaymentDialog = () => {
    setPaymentDialogOpen(false);
  };

  const handleStartTour = () => {
    setIsSidebarOpen(false);
    setStatisticsAccordionExpanded(true);
    setPatternsAccordionExpanded(true);
    setTimeout(() => {
      setIsOpen(true);
    }, 300);
  };

  const menuItems = [
    { 
      text: 'Onboarding Tour', 
      icon: <RiPresentationFill size={20} />, 
      onClick: handleStartTour, 
      dataTour: "onboarding" 
    },
    { 
      text: 'Information', 
      icon: <InfoIcon fontSize="small" />, 
      onClick: navigateToAppInfo 
    },
    { 
      text: 'Contact', 
      icon: <EmailIcon fontSize="small" />, 
      onClick: handleOpenContact, 
      dataTour: "contact" 
    },
    { 
      text: 'Terms of Service', 
      icon: <IoMdDocument size={20} />, 
      onClick: handleClickOpenToSRoute 
    },
  ];

  const list = () => (
    <List>
      {menuItems.map((item, index) => (
        <ListItem key={index} disablePadding>
          <ListItemButton onClick={item.onClick} data-tour={item.dataTour}>
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText primary={item.text} />
          </ListItemButton>
        </ListItem>
      ))}
    </List>
  );

  return (
    <>
      <AppBar
        position="static"
        enableColorOnDark={true}
        color='primary'
        sx={{
          backgroundColor: theme.palette.primary.main,
          mb: '1em',
        }}
      >
        <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <IconButton
              data-tour="appbar-button"
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
              onClick={toggleDrawer(true)}
            >
              <MenuIcon fontSize="large"/>
            </IconButton>
            <HomeButtonWithLogo onClick={navigateToRoot} />
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <VideoLibrary/>
            <PaletteSelector />
            <AvatarSelector />
            <FontSelector />
            <AuthButton />
          </Box>
        </Toolbar>
      </AppBar>

      <Drawer
        data-tour="sidebar-drawer"
        anchor="left"
        open={isSidebarOpen}
        onClose={toggleDrawer(false)}
        variant="temporary"
      >
        {list()}
      </Drawer>

      {/* Dialogs */}
      <ContactDialog open={contactOpen} onClose={handleCloseContact} />

      <Dialog open={roadMapOpen} onClose={handleCloseRoadmap}>
        <DialogTitle>Roadmap</DialogTitle>
        <DialogContent dividers>
          <RoadMap />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseRoadmap} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={paymentDialogOpen} onClose={handleClosePaymentDialog}>
        <DialogTitle>Subscribe to Typecelerate</DialogTitle>
        <DialogContent dividers>
          {isUserLoading ? (
            <CircularProgress />
          ) : currUser ? (
            <PayPalSubscriptionButton userId={currUser.uid} />
          ) : (
            <Typography variant="body2" paragraph>
              You need to be logged in to subscribe to Typecelerate.
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClosePaymentDialog} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SidebarMenu;