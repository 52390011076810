
import React from 'react';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

function SlidingLetter({ 
  letter, 
  isSpace,
  correctness,
  index
}) {
  const theme = useTheme();
  let color;

  switch (correctness) {
    case 'correct':
      color = theme.palette.mode === 'light' ? theme.palette.success.light : theme.palette.success.dark;
      break;
    case 'corrected':
      color = theme.palette.mode === 'light' ? theme.palette.success.light : theme.palette.success.dark;
      break;
    case 'incorrect':
      color = theme.palette.mode === 'light' ? theme.palette.error.light : theme.palette.error.dark;
      break;
    default:
      color = theme.palette.text.primary; // Ensure default color is set correctly
  }

  return (
    <Box
      component="span"
      sx={{
        color,
        position: 'relative',
        zIndex: 2,
        textDecoration: correctness === 'incorrect' ? 'underline' : 'none',
        fontSize: '30px',
        lineHeight: 1.5,
        display: 'inline-block'
      }}
      data-index={index}
    >
      {isSpace ? '\u00A0' : letter}
    </Box>
  );
}

const MemoizedSlidingLetter = React.memo(SlidingLetter);

export { SlidingLetter, MemoizedSlidingLetter };