import React, { useEffect, useState, useContext, useRef, useMemo, useCallback } from 'react';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  CircularProgress, Typography, Button, Box,
  Snackbar, Dialog, DialogActions, DialogContent, DialogContentText,
  DialogTitle } from '@mui/material';
import Alert from '@mui/material/Alert';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import DeleteIcon from '@mui/icons-material/Delete';

// import axios from 'axios';
import html2canvas from 'html2canvas';

import { TTDropdownMenu } from './TTDropdownMenu';
import { MainContentContext } from '../contexts/MainContentContext';
import { SuperMainContentContext } from '../contexts/SuperMainContext';
import { AppContext } from '../contexts/AppContext'

import { useTheme } from '@mui/material/styles';
import {
  wordListSizes, testLengths, languages, defaultLanguage, 
} from '../utils/defaultValues';
import { getPersonalBests, updatePersonalBest, resetPersonalBests } from '../utils/firestoreUtils';
import MascotIcon from './MascotIcon';
import { debounce } from 'lodash';

const wordPools = wordListSizes;
const lengths = testLengths;


const StatisticsTable = ({ isCapture, statistics, selectedLanguage, handleLanguageChange }) => {


  const theme = useTheme();

  return (
    <TableContainer component={Box} sx={{
      backgroundColor: isCapture ? 'transparent' : theme.palette.background.paper
    }}>
      <Table aria-label="user statistics table">
        <TableHead>
          <TableRow>
            <TableCell align="center" sx={{
              borderColor: theme.palette.divider,
              backgroundColor: isCapture ? 'transparent' : undefined
            }}>
              <TTDropdownMenu
                variant='filled'
                title="Language"
                options={languages}
                defaultOption={defaultLanguage}
                onChange={handleLanguageChange}
                value={selectedLanguage || defaultLanguage}
              />
            </TableCell>
            {wordPools.map((pool) => (
              <TableCell key={pool} align="center" sx={{
                borderColor: theme.palette.divider,
                backgroundColor: isCapture ? 'transparent' : undefined
              }}>
                <Typography>
                  {pool}
                </Typography>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {lengths.map((length) => (
            <TableRow key={length}>
              <TableCell align="center" sx={{
                borderColor: theme.palette.divider,
                backgroundColor: isCapture ? 'transparent' : undefined
              }}>
                {length}
              </TableCell>
              {wordPools.map((pool) => (
                <TableCell key={`${length}-${pool}`} align="center" sx={{
                  borderColor: theme.palette.divider,
                  backgroundColor: isCapture ? 'transparent' : undefined
                }}>
                  {statistics[selectedLanguage]?.[pool]?.[length]?.value !== undefined
                    ? `${statistics[selectedLanguage][pool][length].value} wpm`
                    : 'N/A'}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};


const UserStatistics = () => {
  const { currUser, isUserLoading } = useContext(AppContext);
  const {
    selectedStatisticsLanguage,
    setSelectedStatisticsLanguage,
    setShowNewRecord
  } = useContext(SuperMainContentContext);
  const {
    WPM,
    testCount,
    isTestActive,
    statistics,
    setStatistics,
  } = useContext(MainContentContext);
  const {
    selectedTestLength,
    selectedWordList,
    selectedLanguage,
    isFullyRandom
  } = useContext(SuperMainContentContext);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const captureRef = useRef(null);
  const [isCapturing, setIsCapturing] = useState(false);

  //This is utterly dumb.
  //It saves a record even if a test is cancelled and not finished
  //because it's really only looking at isTestActive
  // useEffect(() => {
  //   // Only check for records when a test completes
  //   if (isFullyRandom.current && !isTestActive) {
  //     const currentBest = statistics[selectedLanguage]?.[selectedWordList]?.[selectedTestLength]?.value ?? 0;
  //     console.log('Test completed, checking for new record:', {
  //       WPM,
  //       currentBest,
  //       selectedTestLength,
  //       selectedWordList,
  //       selectedLanguage
  //     });
    
    
  //     if (WPM > currentBest) {
  //       console.log('New personal best:', WPM);
  //       setShowNewRecord(true);
  //       updateUserPersonalBest(WPM, selectedTestLength, selectedWordList, selectedLanguage);
      
  //       setStatistics(prevStats => ({
  //         ...prevStats,
  //         [selectedLanguage]: {
  //           ...prevStats[selectedLanguage],
  //           [selectedWordList]: {
  //             ...prevStats[selectedLanguage]?.[selectedWordList],
  //             [selectedTestLength]: {
  //               value: WPM,
  //               achieved_at: new Date()
  //             }
  //           }
  //         }
  //       }));
  //     }
  //   }
  // }, [isTestActive]);

  const theme = useTheme();

  const captureBackgroundGradient = useMemo(() =>
    `linear-gradient(45deg, ${theme.palette.primary.dark}, ${theme.palette.secondary.light})`,
    [theme.palette.primary.dark, theme.palette.secondary.light]
  );

  const handleTestLanguageChange = (event) => {
    setSelectedStatisticsLanguage(event.target.value);
  };
  
  useEffect(() => {
    const fetchStatistics = async () => {
      if (currUser) {
        try {
          setLoading(true);
          const personalBests = await getPersonalBests(currUser.uid);
          console.log('Fetched statistics:', personalBests);
          setStatistics(personalBests);
          setLoading(false);
        } catch (err) {
          setError('Failed to fetch statistics');
          setLoading(false);
        }
      }
    };
    if (!isUserLoading && currUser) {
      fetchStatistics();
    }
  }, [selectedStatisticsLanguage, currUser, isUserLoading]);

  // Function to update personal best (call this when user completes a test)
  const updateUserPersonalBest = async (wpm, testLength, wordList, language) => {
    if (currUser) {
      try {
        await updatePersonalBest(
          currUser.uid,
          language,
          wordList,
          testLength,
          wpm
        );
      } catch (error) {
        console.error('Error updating personal best:', error);
      }
    }
  };

  const captureAndCopyStatistics = async () => {
    // setIsCapturing(true);
    if (captureRef.current) {
      try {
        const canvas = await html2canvas(captureRef.current, {
          backgroundColor: theme.palette.background.paper,
          scale: 2,
        });
      
        const blob = await new Promise((resolve, reject) => {
          canvas.toBlob((blob) => {
            if (blob) {
              resolve(blob);
            } else {
              reject(new Error("Failed to create blob from canvas"));
            }
          }, 'image/png');
        });
      
        const item = new ClipboardItem({ "image/png": blob });
        await navigator.clipboard.write([item]);
      
        setSnackbarMessage("Statistics image copied to clipboard!");
        setSnackbarOpen(true);
      } catch (err) {
        console.error("Error in captureAndCopyStatistics:", err);
        setSnackbarMessage("Please keep the browser tab active during the capture process.");
        setSnackbarOpen(true);
      } finally {
        setIsCapturing(false);
      }
    }
  };

  const debouncedCapture = useCallback(
    debounce(() => {
      captureAndCopyStatistics();
    }, 300),
    []
  );

  const handleCaptureClick = () => {
    setIsCapturing(true);
    debouncedCapture();
  };

  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [isClearing, setIsClearing] = useState(false);

  const handleClearPersonalBests = () => {
    setIsConfirmDialogOpen(true);
  };

  const handleConfirmClear = async () => {
    setIsClearing(true);
    setIsConfirmDialogOpen(false);
    
    if (currUser) {
      try {
        await resetPersonalBests(currUser.uid);
        const stats = await getPersonalBests(currUser.uid);
        setStatistics(stats);
        // setStatistics({}); // Clear statistics in the state
        setError(null); // Clear any existing errors
      } catch (err) {
        setError('Failed to clear statistics');
      } finally {
        setIsClearing(false);
      }
    }
  };

  const handleCancelClear = () => {
    setIsConfirmDialogOpen(false);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  // if (isUserLoading || loading) {
  //   return (
  //     <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
  //       <CircularProgress />
  //     </Box>
  //   );
  // }

  if (!currUser) return <Box>Please log in to view your statistics.</Box>;


  
  return (
    <Box sx={{ width: '100%' }}>
      {/* <ComingSoonMessage /> */}
      <Typography variant="h6" sx={{ mb: "1em" }}>User Statistics - only measured for random tests</Typography>
      
      {/* Visible statistics table */}
      {/* {loading || isUserLoading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <CircularProgress size={264} />
        </Box>
      ) : */}
        <Box
          data-tour="user-statistics"
          sx={{
            ...theme.customStyles.patternsBox,
            backgroundColor: theme.palette.background.paper,
            borderColor: theme.palette.divider,
            width: '100%',
          }}
        >
          <StatisticsTable
            isCapture={false}
            statistics={statistics}
            selectedLanguage={selectedStatisticsLanguage}
            handleLanguageChange={handleTestLanguageChange}
          />
        </Box>
      {/* } */}
      {/* Hidden capture area with watermark */}
      <Box
        ref={captureRef}
        sx={{
          position: 'absolute',
          left: '-9999px',
          top: '-9999px',
          width: '600px',
          height: '400px',
          padding: 3,
          // backgroundColor: theme.palette.background.paper,
          background: captureBackgroundGradient,
          overflow: 'hidden',
        }}
      >
        {/* Watermark */}

        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            opacity: 0.2,
            pointerEvents: 'none',
            transform: 'rotate(-30deg) scale(4)',
            color: theme.palette.text.secondary,
          }}
        >
          <MascotIcon sx={{
            width: '5em',
            height: '5em',
          }} />
        </Box>

        {/* old watermark using an image instead of an icon */}
        {/* <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            opacity: 0.5,
            pointerEvents: 'none',
            // backgroundImage: 'url("/favicon.ico")',
            color: 'text.secondary',
            backgroundImage: `url(${mascotImage})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'contain',
            transform: 'rotate(-30deg) scale(1)',
          }}
        /> */}

        {/* Content */}
        <Box sx={{ position: 'relative', zIndex: 2, width: '100%' }}>
          <Typography variant="h6" align="center" gutterBottom>
            typecelerate.com
          </Typography>
          <StatisticsTable
            isCapture={true}
            statistics={statistics}
            selectedLanguage={selectedStatisticsLanguage}
            handleLanguageChange={handleTestLanguageChange}
          />
        </Box>
      </Box>

      <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Button
          variant="contained"
          color="primary"
          startIcon={isCapturing ? <CircularProgress size={24} /> : <CameraAltIcon />}
          onClick={handleCaptureClick}
          disabled={isCapturing}
        >
          {isCapturing ? "Capturing..." : "Capture and Copy Statistics"}
        </Button>

        <Button
          variant="contained"
          color="primary"
          startIcon={isClearing ? <CircularProgress size={24} /> : <DeleteIcon />}
          onClick={handleClearPersonalBests}
          disabled={isClearing}
        >
          {isClearing ? "Clearing..." : "Clear Personal Bests"}
        </Button>

      </Box>

      <Dialog
        open={isConfirmDialogOpen}
        onClose={handleCancelClear}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Clear Personal Bests?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to clear all your personal best statistics? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelClear} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmClear} color="primary" autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      // message={snackbarMessage}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarMessage.includes('copied') ? 'success' : 'error'}
          sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export { UserStatistics };