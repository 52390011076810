import { db } from './firebaseInit';
import { logger, measurePerformance } from './logger';
import palettes from "../themes/palettes.js";
import fonts from "../utils/fonts.js";
import { testLengths, wordListSizes, languages } from '../utils/defaultValues.js';

import {
  doc,
  setDoc,
  getDoc,
  updateDoc,
  serverTimestamp,
  collection,
  getDocs,
  deleteDoc,
  writeBatch
} from 'firebase/firestore';
import { builtInProfiles } from '../utils/builtInProfiles'
import { builtInPresets } from '../utils/builtInPresets'


const paletteNames = Object.keys(palettes);
const fontNames = Object.keys(fonts);

const DEFAULT_USER_SETTINGS = {
  subscription_status: 'free',
  optional_features: {},
  palette: paletteNames[0],
  font: fontNames[0],
  hasSeenTour: false
};

//CREATE USER
export const createUserDocument = async (user) => {
  // Input validation
  if (!user?.uid || !user?.email) {
    throw new Error('Invalid user object provided');
  }

  try {
    // Create batch for atomic operations
    const batch = writeBatch(db);

    // Set up user document
    const userRef = doc(db, 'users', user.uid);
    batch.set(userRef, {
      username: user.displayName || '',
      email: user.email,
      created_at: serverTimestamp(),
      last_login: serverTimestamp()
    });

    // Set up user settings document
    const settingsRef = doc(db, 'user_settings', user.uid);
    batch.set(settingsRef, DEFAULT_USER_SETTINGS);

    // Commit core user data creation
    try {
      await batch.commit();
    } catch (batchError) {
      console.error('Error in batch operations:', batchError);
      throw new Error('Failed to create core user documents');
    }

    // Initialize additional user data with fallbacks
    try {
      await Promise.allSettled([
        addBuiltInProfiles(user.uid).catch(error => {
          console.error('Error adding built-in profiles:', error);
          return null;
        }),
        createEmptyPersonalBests(user.uid).catch(error => {
          console.error('Error creating personal bests:', error);
          return null;
        }),
        addBuiltInPresets(user.uid).catch(error => {
          console.error('Error adding built-in presets:', error);
          return null;
        }),
      ]);
    } catch (initError) {
      console.error('Error in additional initialization:', initError);
      // Don't throw here - core user data was created successfully
    }

    return {
      success: true,
      userId: user.uid
    };

  } catch (error) {
    console.error('Error creating user document:', error);
    throw error;
  }
};

export const updateNewLogin = async (userId) => {
  try {
    const userRef = doc(db, 'users', userId);
    await updateDoc(userRef, {
      last_login: serverTimestamp()
    });
  } catch (error) {
    console.error('Error updating last login:', error);
    throw error;
  }
};

export const checkUserExists = async (userId) => {
  try {
    const userRef = doc(db, 'users', userId);
    const userDoc = await getDoc(userRef);
    return userDoc.exists();
  } catch (error) {
    logger.error('Error checking user exists:', error);
    throw error;
  }
};

//USER SETTINGS OPERATIONS
// In firestoreUtils.js, enhance error logging:
export const getUserSettings = async (userId) => {
  try {
    logger.debug('Fetching user settings', { userId });

    return await measurePerformance('getUserSettings', async () => {
      const settingsRef = doc(db, 'user_settings', userId);
      const settingsDoc = await getDoc(settingsRef);

      logger.debug('User settings fetched', {
        userId,
        exists: settingsDoc.exists(),
        data: settingsDoc.exists() ? 'present' : 'missing'
      });

      return settingsDoc.exists() ? settingsDoc.data() : {};
    });
  } catch (error) {
    logger.error('Failed to fetch user settings', error, { userId });
    throw error;
  }
};

export const updateUserPalette = async (userId, paletteName) => {
  try {
    console.log("UPDATEUSERPALETTE")
    const settingsRef = doc(db, 'user_settings', userId);
    await updateDoc(settingsRef, {
      palette: paletteName
    });
  } catch (error) {
    const print = {
      code: error.code,
      message: error.message,
      userId: userId,
      stack: error.stack
    }
    console.error('Error updating user palette:', print);
    alert("Error updating user palette: ", print, "Please contact Admin")
    throw error;
  }
};

export const updateUserFont = async (userId, fontName) => {
  try {
    const settingsRef = doc(db, 'user_settings', userId);
    await updateDoc(settingsRef, {
      font: fontName
    });
  } catch (error) {
    const print = {
      code: error.code,
      message: error.message,
      userId: userId,
      stack: error.stack
    }
    console.error('Error updating user font:', print);
    alert("Error updating user font: ", print, "Please contact Admin")
    throw error;
  }
};

export const updateUserTourStatus = async (userId, hasSeenTour) => {
  try {
    const settingsRef = doc(db, 'user_settings', userId);
    await updateDoc(settingsRef, {
      hasSeenTour: hasSeenTour
    });
  } catch (error) {
    const print = {
      code: error.code,
      message: error.message,
      userId: userId,
      stack: error.stack
    }
    console.error('Error updating user tour status:', print);
    alert("Error updating user tour status: ", print, "Please contact Admin")
    throw error;
  }
};


// PRESETS OPERATIONS
// very similar to profiles but all functions will be empty for now:
export const addBuiltInPresets = async (userId) => {
  try {
    const presetsRef = collection(db, 'presets', userId, 'user_presets');
    const querySnapshot = await getDocs(presetsRef);
    const existingPresets = querySnapshot.docs.map(doc => doc.id);
    const builtInPresetNames = Object.keys(builtInPresets);

    await Promise.all(
      builtInPresetNames.map(async (presetName) => {
        if (!existingPresets.includes(presetName)) {
          await addPreset(userId, {
            name: presetName,
            settings: builtInPresets[presetName].settings
          });
        }
      })
    );
  } catch (error) {
    console.error('Error adding built-in presets:', error);
    throw error;
  }
};

export const addPreset = async (userId, preset) => {
  console.log("ADDPRESET")
  try {
    if (!preset.name) {
      throw new Error('Preset must have a name');
    }
    const presetRef = doc(db, 'presets', userId, 'user_presets', preset.name);
    await setDoc(presetRef, {
      lastModified: serverTimestamp(),
      settings: preset.settings || {}
    });
  } catch (error) {
    console.error('Error adding preset:', error);
    throw error;
  }
};

export const deletePreset = async (userId, presetName) => {
  console.log("DELETEPRESET")
  try {
    const presetRef = doc(db, 'presets', userId, 'user_presets', presetName);
    await deleteDoc(presetRef);
  } catch (error) {
    console.error('Error deleting preset:', error);
    throw error;
  }
};

export const modifyPreset = async (userId, preset) => {
  console.log("MODIFYPRESET")
  try {
    if (!preset.name) {
      throw new Error('Preset must have a name');
    }
    const presetRef = doc(db, 'presets', userId, 'user_presets', preset.name);
    await updateDoc(presetRef, {
      lastModified: serverTimestamp(),
      settings: preset.settings || {}
    });
  } catch (error) {
    console.error('Error modifying preset:', error);
    throw error;
  }
};

export const getPreset = async (userId, presetName) => {
  console.log("GETPRESET")
  if (!userId) {
    throw new Error('userId is required');
  }
  if (!presetName) {
    throw new Error('presetName is required');
  }
  try {
    const presetRef = doc(db, 'presets', userId, 'user_presets', presetName);
    const presetDoc = await getDoc(presetRef);
    return presetDoc.exists() ? presetDoc.data() : {};
  } catch (error) {
    console.error('Error getting preset:', error);
    throw error;
  }
};

export const getPresets = async (userId) => {
  try {
    const presetsRef = collection(db, 'presets', userId, 'user_presets');
    const querySnapshot = await getDocs(presetsRef);
    const presets = [];
    querySnapshot.forEach((doc) => {
      presets.push({ id: doc.id, ...doc.data() });
    });
    return presets;
  } catch (error) {
    console.error('Error getting presets:', error);
    throw error;
  }
};

export const resetPresets = async (userId) => {
  try {
    // Delete all existing presets
    const presetsRef = collection(db, 'presets', userId, 'user_presets');
    const querySnapshot = await getDocs(presetsRef);
    const batch = writeBatch(db);
    
    querySnapshot.forEach((doc) => {
      batch.delete(doc.ref);
    });
    
    await batch.commit();

    // Add built-in presets fresh
    await addBuiltInPresets(userId);

  } catch (error) {
    console.error('Error resetting presets:', error);
    throw error;
  }
};
// PROFILE OPERATIONS
// Add all the built-in profiles to the user's profile collection. Make sure
// to check if the profile already exists before adding it.
export const addBuiltInProfiles = async (userId) => {
  try {
    const profilesRef = collection(db, 'profiles', userId, 'user_profiles');
    const querySnapshot = await getDocs(profilesRef);
    const existingProfiles = querySnapshot.docs.map(doc => doc.id);
    const builtInProfileNames = Object.keys(builtInProfiles);

    // Use Promise.all to wait for all profile additions to complete
    await Promise.all(
      builtInProfileNames.map(async (profileName) => {
        if (!existingProfiles.includes(profileName)) {
          await addProfile(userId, {
            name: profileName,
            include: builtInProfiles[profileName].include,
            exclude: builtInProfiles[profileName].exclude
          });
        }
      })
    );
  } catch (error) {
    console.error('Error adding built-in profiles:', error);
    throw error;
  }
};

//add a profile to the user's profile collection
export const addProfile = async (userId, profile) => {
  console.log("ADDPROFILE")
  try {
    if (!profile.name) {
      throw new Error('Profile must have a name');
    }
    const profileRef = doc(db, 'profiles', userId, 'user_profiles', profile.name);
    await setDoc(profileRef, {
      include: profile.include || [],
      exclude: profile.exclude || []
    });
  } catch (error) {
    console.error('Error adding profile:', error);
    throw error;
  }
};

//delete a profile from the user's profile collection
export const deleteProfile = async (userId, profileName) => {
  console.log("DELETEPROFILE")
  console.log("userId", userId)
  console.log("profileName", profileName)
  try {
    const profileRef = doc(db, 'profiles', userId, 'user_profiles', profileName);
    await deleteDoc(profileRef);
  } catch (error) {
    console.error('Error deleting profile:', error);
    throw error;
  }
};

//modify a profile in the user's profile collection
export const modifyProfile = async (userId, profile) => {
  console.log("MODIFYPROFILE")
  try {
    if (!profile.name) {
      throw new Error('Profile must have a name');
    }
    const profileRef = doc(db, 'profiles', userId, 'user_profiles', profile.name);
    await updateDoc(profileRef, {
      include: profile.include || [],
      exclude: profile.exclude || []
    });
  } catch (error) {
    console.error('Error modifying profile:', error);
    throw error;
  }
};

//get all the profiles for a user - returns an array of profiles
export const getProfiles = async (userId) => {
  try {
    const profilesRef = collection(db, 'profiles', userId, 'user_profiles');
    const querySnapshot = await getDocs(profilesRef);
    const profiles = [];
    querySnapshot.forEach((doc) => {
      profiles.push({ id: doc.id, ...doc.data() });
    });
    return profiles;
  } catch (error) {
    console.error('Error getting profiles:', error);
    throw error;
  }
};

export const getProfile = async (userId, profileName) => {
  console.log("GETPROFILE")
  if (!userId) {
    throw new Error('userId is required');
  }
  if (!profileName) {
    throw new Error('profileName is required');
  }
  try {
    const profilePath = ['profiles', userId, 'user_profiles', profileName];
    const profileRef = doc(db, ...profilePath);
    const profileDoc = await getDoc(profileRef);
    return profileDoc.exists() ? profileDoc.data() : {};
  } catch (error) {
    console.error('Error getting profile:', error);
    console.error('Error details:', {
      name: error.name,
      message: error.message,
      stack: error.stack
    });
    throw error;
  }
};

export const createProfile = async (userId, profileData) => {
  try {
    const profileRef = doc(db, 'profiles', userId, 'user_profiles', profileData.name);
    await setDoc(profileRef, {
      name: profileData.name,
      active: true,
      settings: {
        included_patterns: profileData.settings.included_patterns || [],
        excluded_patterns: profileData.settings.excluded_patterns || [],
        // Add other profile settings here
      }
    });
  } catch (error) {
    console.error('Error creating profile:', error);
    throw error;
  }
};


// PERSONAL BESTS OPERATIONS
export const createEmptyPersonalBests = async (userId) => {
  try {
    const personalBestRef = doc(db, 'personal_bests', userId);
    const personalBestDoc = await getDoc(personalBestRef);

    if (!personalBestDoc.exists()) {
      const emptyData = {};
      languages.forEach(language => {
        emptyData[language] = {};
        wordListSizes.forEach(wordList => {
          emptyData[language][wordList] = {};
          testLengths.forEach(testLength => {
            emptyData[language][wordList][testLength] = {
              value: 0,
              achieved_at: null
            };
          });
        });
      });

      await setDoc(personalBestRef, emptyData);
    }
  } catch (error) {
    console.error('Error creating empty personal bests:', error);
    throw error;
  }
}

export const createEmptyPersonalBest = async (userId, wpm, language, wordList, testLength) => {
  try {
    const personalBestRef = doc(db, 'personal_bests', userId);
    await setDoc(personalBestRef, {
      [language]: {
        [wordList]: {
          [testLength]: {
            value: wpm,
            achieved_at: serverTimestamp()
          }
        }
      }
    });
  } catch (error) {
    console.error('Error creating personal best:', error);
    throw error;
  }
}

//update all pbs as 0
export const resetPersonalBests = async (userId) => {
  try {
    const personalBestRef = doc(db, 'personal_bests', userId);
    const personalBestDoc = await getDoc(personalBestRef);

    if (personalBestDoc.exists()) {
      const emptyData = {};
      languages.forEach(language => {
        emptyData[language] = {};
        wordListSizes.forEach(wordList => {
          emptyData[language][wordList] = {};
          testLengths.forEach(testLength => {
            emptyData[language][wordList][testLength] = {
              value: 0,
              achieved_at: null
            };
          });
        });
      });

      await setDoc(personalBestRef, emptyData);
    }
  } catch (error) {
    console.error('Error creating empty personal bests:', error);
    throw error;
  }
}

// Personal Bests Operations
export const updatePersonalBest = async (userId, languageId, wordListId, testLengthId, value) => {
  try {
    const bestRef = doc(db, 'personal_bests', userId);
    const bestDoc = await getDoc(bestRef);

    if (!bestDoc.exists()) {
      // Create new document with nested structure
      await setDoc(bestRef, {
        [languageId]: {
          [wordListId]: {
            [testLengthId]: {
              value,
              achieved_at: serverTimestamp()
            }
          }
        }
      });
    } else {
      // Update existing document
      const currentData = bestDoc.data();
      const currentBest = currentData?.[languageId]?.[wordListId]?.[testLengthId]?.value || 0;

      if (value > currentBest) {
        await updateDoc(bestRef, {
          [`${languageId}.${wordListId}.${testLengthId}`]: {
            value,
            achieved_at: serverTimestamp()
          }
        });
      }
    }
  } catch (error) {
    console.error('Error updating personal best:', error);
    throw error;
  }
};

export const getPersonalBests = async (userId) => {
  try {
    const bestRef = doc(db, 'personal_bests', userId);
    const bestDoc = await getDoc(bestRef);
    return bestDoc.exists() ? bestDoc.data() : {};
  } catch (error) {
    console.error('Error getting personal bests:', error);
    throw error;
  }
};