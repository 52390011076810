import React, { useEffect, useRef, useState } from 'react';
import { Box, CircularProgress } from '@mui/material';

import ComingSoonMessage from './ComingSoonMessage';


const PayPalSubscriptionButton = ({ userId }) => {
  const paypalRef = useRef(null);
  const [sdkReady, setSdkReady] = useState(false);

  useEffect(() => {
    const addPayPalScript = () => {
      const script = document.createElement('script');
      script.src = `https://www.paypal.com/sdk/js?client-id=AVIVe7TDr3mgYd_3mtBE_qSddfojAZGdAxBlbvROU26t9_uH45Vlb8H2TwKjNZTyppviZMDXraPjgWkU&vault=true&intent=subscription`;
      script.async = true;
      script.onload = () => setSdkReady(true);
      document.body.appendChild(script);
    };

    if (!window.paypal) {
      addPayPalScript();
    } else {
      setSdkReady(true);
    }
  }, []);

  useEffect(() => {
    if (sdkReady && paypalRef.current) {
      window.paypal.Buttons({
        style: {
          shape: 'pill',
          color: 'gold',
          layout: 'vertical',
          label: 'subscribe'
        },
        createSubscription: function(data, actions) {
          return actions.subscription.create({
            plan_id: 'P-04N131355N8870923M3LA3IQ',
            custom_id: userId,
          });
        },
        onApprove: function(data, actions) {
          console.log('Subscription approved:', data);
          // Here you would typically call your backend API to record the subscription
          //TODO rhig : take care of this shit please? 😥
          // I think this is where we're supposed to update the DB to say "this guy paid"
          alert('Subscription successful! SubscriptionID: ' + data.subscriptionID);
        }
      }).render(paypalRef.current);
    }
  }, [sdkReady, userId]);

  if (!sdkReady) {
    return <CircularProgress />;
  }

  return (
    <Box>
      <ComingSoonMessage />
      <Box ref={paypalRef} />
    </Box>
  );
    
};

export default PayPalSubscriptionButton;