import React, { createContext, useState, useEffect, useRef } from "react";
import { onAuthStateChanged, getRedirectResult } from "firebase/auth";
import { auth } from "../utils/firebaseInit.js";
import palettes from "../themes/palettes.js";
import fonts from "../utils/fonts.js";
// import axios from "axios";
import { updateUserPalette, updateUserFont, getUserSettings, updateNewLogin } from "../utils/firestoreUtils";

const paletteNames = Object.keys(palettes);
const fontNames = fonts.map((font) => font.font);

const DEFAULT_PALETTE = paletteNames[0];
const DEFAULT_FONT = "Courier Prime";

// Validation functions
const validatePaletteName = (paletteName) => {
  return paletteNames.includes(paletteName) ? paletteName : DEFAULT_PALETTE;
};

const validateFontName = (fontName) => {
  return fontNames.includes(fontName) ? fontName : DEFAULT_FONT;
};

export const AppContext = createContext();

export const AppProvider = ({ children }) => {

  const [currUser, setCurrUser] = useState(null);
  const [isUserLoading, setIsUserLoading] = useState(true);
  const [isFocused, setIsFocused] = useState(true);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const [displayedPalette, setDisplayedPalette] = useState(DEFAULT_PALETTE);
  const [displayedFont, setDisplayedFont] = useState(DEFAULT_FONT);
  const clickedPalette = useRef(displayedPalette);
  const clickedFont = useRef(displayedFont);
  const [userSettingsLoaded, setUserSettingsLoaded] = useState(false);

  const [isWebsiteReady, setIsWebsiteReady] = useState(false);
  const [showNDA, setShowNDA] = useState(false);




  useEffect(() => {
    if (!isUserLoading && !currUser) {
      setShowNDA(false);
    }
    else {
      //I don't want the NDA anymore
      setShowNDA(false);
    }
  }, [isUserLoading, currUser]);

  useEffect(() => {
    const ready = !isUserLoading && (userSettingsLoaded || !currUser);
  
    if (ready) {
      const timeoutId = setTimeout(() => {
        setIsWebsiteReady(true);
      }, 500);
      return () => clearTimeout(timeoutId);
    } else {
      setIsWebsiteReady(false);
    }
  }, [isUserLoading, userSettingsLoaded, currUser]);

useEffect(() => {
  console.log("Palette changed via user:", clickedPalette.current);
  if (!isUserLoading && currUser?.uid && userSettingsLoaded && clickedPalette.current) {
    updateUserPalette(currUser.uid, clickedPalette.current)
      .catch(error => {
        console.error("Error updating user palette:", error);
        // Optionally show a user-friendly error message
      });
  }
}, [clickedPalette.current, isUserLoading, currUser, userSettingsLoaded]);

  //font
  useEffect(() => {
    console.log("Font changed via user:", clickedFont.current);
    if (!isUserLoading && currUser && currUser.uid && userSettingsLoaded && clickedFont.current) {
      updateUserFont(currUser.uid, clickedFont.current);
    }
    // localStorage.setItem("font", displayedFont);
  }, [clickedFont.current, isUserLoading, currUser, userSettingsLoaded]);

  useEffect(() => {
    console.log("Settings load effect triggered. isUserLoading:", isUserLoading, "currUser:", currUser?.uid);
 
    if (!isUserLoading) {
      let savedPalette = "";
      let savedFont = "";
   
      if (currUser && currUser.uid) {
        console.log("User is logged in, fetching settings for:", currUser.uid);
     
        getUserSettings(currUser.uid)
          .then((settings) => {
            console.log("Received settings from DB:", settings);
         
            savedPalette = settings.palette;
            console.log("About to set palette from:", savedPalette);
            setDisplayedPalette(validatePaletteName(savedPalette));
            clickedPalette.current = savedPalette;
            console.log("After setting palette - clicked:", clickedPalette.current);
         
            savedFont = settings.font;
            console.log("About to set font from:", savedFont);
            setDisplayedFont(validateFontName(savedFont));
            clickedFont.current = savedFont;
            console.log("After setting font - clicked:", clickedFont.current);
         
            updateNewLogin(currUser.uid);
            setUserSettingsLoaded(true);
            console.log("User settings loaded successfully");
          })
          .catch((error) => {
            console.error("Error getting user settings:", error);
            console.log("Setting default values due to error");
            setDisplayedPalette(validatePaletteName(DEFAULT_PALETTE));
            setDisplayedFont(validateFontName(DEFAULT_FONT));
            setUserSettingsLoaded(true);
          });
      } else {
        console.log("No user logged in, setting default values");
        setDisplayedPalette(validatePaletteName(DEFAULT_PALETTE));
        setDisplayedFont(validateFontName(DEFAULT_FONT));
      }
    }
  }, [isUserLoading, currUser]);

  useEffect(() => {
    // Listen for auth state changes
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        console.log("User signed in:", user.uid);
        setCurrUser(user);
      } else {
        console.log("User signed out");
        setCurrUser(null);
        // If I don't do this, the next user to sign in will see the previous user's settings
        setUserSettingsLoaded(false);
      }
      setIsUserLoading(false);
    });

    // Handle redirect result from popup fallback
    getRedirectResult(auth).catch((error) => {
      console.error("Redirect result error:", error.code, error.message);
    });

    // Cleanup subscription
    return () => unsubscribe();
  }, []);

  const contextValue = {
    displayedPalette,
    setDisplayedPalette,
    clickedPalette,
    clickedFont,
    currUser,
    setCurrUser,
    isUserLoading,
    isFocused,
    setIsFocused,
    setIsUserLoading,
    displayedFont,
    setDisplayedFont,
    isSidebarOpen,
    setIsSidebarOpen,
    userSettingsLoaded,
    setUserSettingsLoaded,
    isWebsiteReady,
    showNDA
  };

  return (
    <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>
  );
};
