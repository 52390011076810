import React from 'react';
import { Select, MenuItem, FormControl, InputLabel } from '@mui/material';



function TTDropdownMenu({ title, options, defaultOption, value, onChange, variant = 'outlined' }) {

  React.useEffect(() => {
    if (defaultOption && !options.includes(defaultOption)) {
      throw new Error(`Default option "${defaultOption}" is not a valid option.`);
    }
  }, [defaultOption, options]);

  return (
    <FormControl variant={variant} size="small" sx={{ minWidth: '8em' }}>
      <InputLabel>{title}</InputLabel>
      <Select value={value} onChange={onChange} label={title}>
        {options.map((option, index) => (
          <MenuItem key={index} value={option}>
            {option}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export { TTDropdownMenu };
