import React, { useContext } from 'react';
import { logger, measurePerformance } from '../utils/logger';
import {
  signInWithRedirect,
  GoogleAuthProvider,
  signOut,
  signInWithPopup,
  setPersistence,
  browserLocalPersistence
} from "firebase/auth";
import { Button, Avatar, Tooltip } from '@mui/material';
import { AppContext } from '../contexts/AppContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRightFromBracket } from '@fortawesome/free-solid-svg-icons';
import GoogleIcon from '@mui/icons-material/Google';
import { auth, db, firebaseApp } from '../utils/firebaseInit';
import { createUserDocument, checkUserExists } from '../utils/firestoreUtils';

function AuthButton() {
  const { currUser, setCurrUser } = useContext(AppContext);

  const allowedEmails = [
    'typecelerate@gmail.com',
    'tobivargus@gmail.com',
    'xanderglenn@gmail.com',
    'onlybadendings@gmail.com',
    'Korypearman@gmail.com',
    'gary1internet@gmail.com',
    'ekshvaak@gmail.com',
    'zurako002@gmail.com',
    'rayisdeppressed@gmail.com',
    'futuriaself@gmail.com',
    'nimrodnimrodweinberg@gmail.com',
    'nimrodweinberg@gmail.com',
    'drewgduffield@gmail.com',
    '50fkinfingers@gmail.com',
    '50.fkin.fingers@gmail.com',
    'funwithaces@gmail.com',
    'apollo5468@gmail.com',
  ];

  // In AuthButtonFirebase.jsx
  const handleSignIn = async () => {
    logger.info('Starting sign-in process');


    logger.debug('Firebase app state:', {
      app: firebaseApp?.options,
      authInitialized: !!auth,
      dbInitialized: !!db
    });

    const provider = new GoogleAuthProvider();
  
    try {
      await setPersistence(auth, browserLocalPersistence);
      logger.debug('Firebase persistence set');
      const result = await measurePerformance('signInWithPopup',
        () => signInWithPopup(auth, provider)
      );
      if (!allowedEmails.includes(result.user.email)) {
        logger.warn('Unauthorized email attempt', {
          email: result.user.email
        });
        await signOut(auth);
        alert("Sorry, this app is currently in testing mode and access is limited.");
        return;
      }
      logger.info('Sign-in successful', {
        userId: result.user.uid,
        email: result.user.email
      });
      try {
        const userExists = await checkUserExists(result.user.uid);
        if (!userExists) {
          logger.debug('Creating new user document', {
            userId: result.user.uid
          });
          await createUserDocument(result.user);
        }
      } catch (dbError) {
        logger.error('Database operation failed', dbError, {
          operation: 'user_creation',
          userId: result.user.uid
        });
        await signOut(auth);
        alert("There was an error setting up your account. Please try again.");
        return;
      }
      setCurrUser(result.user);
    } catch (error) {
      logger.error('Sign-in error', error);
    
      // Check if it's a popup blocked error
      if (error.code === 'auth/popup-blocked') {
        logger.info('Attempting redirect sign-in after popup blocked');
        try {
          await signInWithRedirect(auth, provider);
          logger.debug('Redirect sign-in initiated');
        } catch (redirectError) {
          logger.error('Redirect sign-in failed', redirectError);
        }
      }
    }
  };

  const handleSignOut = async () => {
    try {
      await signOut(auth);
      setCurrUser(null);
    } catch (error) {
      console.error("Error signing out", error);
    }
  };

  return (
    <Tooltip title={currUser ? `Logout ${currUser.displayName}` : "Login"}>
      <Button
        data-tour="auth-button"
        variant="contained"
        startIcon={
          currUser ? (
            currUser.photoURL ? (
              <Avatar src={currUser.photoURL} alt={currUser.displayName} sx={{ width: 24, height: 24 }} />
            ) : (
              <FontAwesomeIcon icon={faRightFromBracket} flip="horizontal" />
            )
          ) : (
            <GoogleIcon />
          )
        }
        onClick={currUser ? handleSignOut : handleSignIn}
      >
        {currUser ? 'Logout' : 'Login'}
      </Button>
    </Tooltip>
  );
};

export { AuthButton };