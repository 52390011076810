import React from 'react';
import { Box, Typography,  Tooltip } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import InfoIcon from '@mui/icons-material/Info';
import { ProfilesMenu } from './ProfilesMenu';
import { saveLoadTooltip } from '../utils/toolTipTexts';
import Grid from '@mui/material/Unstable_Grid2';


const CustomPatternsDashboard = () => {
  const theme = useTheme();


  return (

    <>
      {/* <Divider textAlign='center' sx={{
        typography: 'p',
        textAlign: 'center',
        borderRadius: 2,
        m: '1em'
      }}>
        <Card
          sx={{
            borderRadius: 20,
            // bgcolor: theme.palette.background.default,
            bgcolor: theme.palette.background.paper,
            color: theme.palette.text.secondary,
          }}
          variant="outlined" >
          <Stack direction="row">
            <Box sx={{ display: 'flex', alignItems: 'center', mx: '10px' }}>
              <DashboardCustomizeIcon size={24} />
            </Box>
            <Typography variant="p" sx={{ pr: "30px" }}  >
              {"Custom Patterns"}
            </Typography>
          </Stack>
        </Card>
      </Divider> */}

      <Box data-tour="save-load-profiles"
        sx={{
          ...theme.customStyles.patternsBox,
          borderColor: theme.palette.divider,
          backgroundColor: theme.palette.background.paper,
        }}
      >
        <Grid
          container
          spacing={2}
          alignItems="center"
          justifyContent="center"
          sx={{
            width: "100%", m: "0"
          }}
        >
          <Grid xs={10}>
            <Typography sx={{ mb: "1em" }}>Save Custom Patterns</Typography>
          </Grid>
          <Grid xs={2}>
            <Box sx={{ padding: 0, textAlign: "right", width: "100%" }}>
              <Tooltip
                title={saveLoadTooltip}
                placement="left-start"
              >
                <InfoIcon />
              </Tooltip>
            </Box>
          </Grid>
        </Grid>
        <ProfilesMenu />
      </Box>
    </>
  )
};

export { CustomPatternsDashboard };