import React, { useState, useRef } from 'react';
import { TextField, Box, IconButton, Chip } from '@mui/material';
import { Close as CloseIcon, Check as CheckIcon } from '@mui/icons-material';


const CustomChip = ({ label, onDelete, onEdit, onEditComplete }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editValue, setEditValue] = useState(label);
  const editBoxRef = useRef(null);
  const inputRef = useRef(null);
  const isClickingInside = useRef(false);


  const handleEditComplete = () => {
    onEdit(label, editValue);
    setIsEditing(false);
    if (onEditComplete) {
      onEditComplete();
    }
  };

  const handleKeyDown = (e) => {
    // Stop event propagation for any key events during editing
    // This prevents Autocomplete from handling these keys
    e.stopPropagation();
    
    if (e.key === 'Enter') {
      e.preventDefault(); // Prevent form submission
      handleEditComplete();
    } else if (e.key === 'Escape') {
      setEditValue(label);
      setIsEditing(false);
    }
  };

  const handleDoubleClick = () => {
    setIsEditing(true);
  };

  const handleBlur = (event) => {
    if (isClickingInside.current) {
      isClickingInside.current = false;
      if (inputRef.current) {
        inputRef.current.focus();
      }
      return;
    }
    handleEditComplete();
  };

  //prevent from the autocomplete component (that contains this chip) 
  //to catch the mousedown event.
 const handleMouseDown = (e) => {
    if (editBoxRef.current?.contains(e.target)) {
      isClickingInside.current = true;
    }
    // Prevent Autocomplete dropdown
    e.stopPropagation();
  };

  return (
    <Box sx={{ m: '2px', display: 'inline-flex', alignItems: 'center' }}>
      {isEditing ? (
        <Box
          ref={editBoxRef}
          onMouseDown={handleMouseDown}
          sx={{
            display: 'flex',
            alignItems: 'center',
            bgcolor: 'action.selected',
            borderRadius: '12px',
            pl: 0.5,
            height: '24px'
          }}>
          <TextField
            inputRef={inputRef}
            size="small"
            value={editValue}
            onChange={(e) => setEditValue(e.target.value)}
            onKeyDown={handleKeyDown}
            onBlur={handleBlur}
            variant="standard"
            sx={{
              // width: '50px',
              '& .MuiInputBase-root': {
                height: '20px',
                fontSize: '1rem',
              },
              '& .MuiInput-underline:before': { display: 'none' },
              '& .MuiInput-underline:after': { display: 'none' }
            }}
            autoFocus
          />
          <IconButton
            size="small"
            onClick={handleEditComplete}
            sx={{
              ml: 0.25,
              mr: 0.25,
              p: 0.25,
              '& svg': {
                fontSize: '0.875rem'
              }
            }}
          >
            <CheckIcon />
          </IconButton>
        </Box>
      ) : (
        <Chip
          label={label}
          onDelete={onDelete}
          onDoubleClick={handleDoubleClick}
          size="small"
          deleteIcon={
            <Box
              sx={{
                width: 14,
                height: 14,
                borderRadius: '50%',
                bgcolor: 'action.hover',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                mr: 0.25
              }}
            >
              <CloseIcon
                sx={{
                  fontSize: 10,
                  color: 'text.primary'
                }}
              />
            </Box>
          }
          sx={{
            height: '24px',
            bgcolor: 'action.selected',
            cursor: 'pointer',
            '& .MuiChip-label': {
              px: 0.75,
              fontSize: '1rem',
              cursor: 'pointer',
            },
            '&:hover': {
              bgcolor: 'action.hover',
            },
          }}
        />
      )}
    </Box>
  );
};

export default CustomChip;