import React from 'react';
import { Box, Paper, Typography } from '@mui/material';
import { isRTL } from '../../../utils/languageUtils.js';

const StaticDisplay = ({
  children,
  containerRef,
  isFocused,
  caretProps,
  selectedLanguage,
  isLoading
}) => {
  const { position, shouldBlink } = caretProps;

  // console.log("StaticDisplay rendered");
 
  return (
    <Box sx={{ position: 'relative' }}>
      <Paper
        ref={containerRef}
        elevation={0}
        data-tour="typing-test"
        data-typing-container
        sx={{
          position: 'relative',
          borderRadius: '20px',
          borderColor: 'rgba(0, 0, 255, 0)',
          width: '100%',
          mb: 2,
          px: 2,
          py: 2,
          backgroundColor: 'background.paper',
          display: 'flex',
          lineHeight: 1.5
        }}
      >
        <Box
          sx={{
            width: '100%',
            filter: !isFocused ? 'blur(2px)' : 'none',
            transition: 'filter 0.2s ease-in-out',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap', 
              width: '100%',
              direction: isRTL(selectedLanguage) ? 'rtl' : 'ltr',
              visibility: isLoading ? 'hidden' : 'visible'
            }}
          >
            {children}
          </Box>
        </Box>

        {!isFocused && (
          <Box
            sx={{
              position: 'absolute',
              left: '50%',
              top: '50%',
              transform: 'translate(-50%, -50%)',
              backgroundColor: 'background.default',
              padding: '0.5em 1em',
              borderRadius: '8px',
            }}
          >
            <Typography variant="h6" sx={{ color: 'text.primary' }}>
              Click here or press Ctrl + Shift + F to focus
            </Typography>
          </Box>
        )}
      </Paper>
    </Box>
  );
};

export default StaticDisplay;