import React, { useContext, useState, useEffect, useRef } from 'react';
import { alpha } from '@mui/material/styles';
import { Box, Typography, useTheme, IconButton, Tooltip} from '@mui/material';
import TimelineIcon from '@mui/icons-material/Timeline';
import { VscGraphScatter } from "react-icons/vsc";
import { MainContentContext } from '../contexts/MainContentContext';
import { SuperMainContentContext } from '../contexts/SuperMainContext';
import AllInclusiveIcon from '@mui/icons-material/AllInclusive';
import { AppContext } from '../contexts/AppContext';
import { AvatarIconWrapper } from './AvatarIconWrapper.jsx';
import CachedIcon from '@mui/icons-material/Cached';

const RefreshButton = ({ onClick, disabled }) => {

  const theme = useTheme();
  return (
    <Tooltip
      title="Reset Progress"
      placement="top"
      arrow
      componentsProps={{
        tooltip: {
          sx: {
            bgcolor: theme.palette.background.paper,
            color: theme.palette.text.primary,
            '& .MuiTooltip-arrow': {
              color: theme.palette.background.paper,
            },
          },
        },
      }}
    >
      <span>
        <IconButton
          disabled={disabled}
          size="small"
          onClick={onClick}
          sx={{
            ml: 1,
            color: theme.palette.text.secondary,
            '&:hover': {
              color: theme.palette.text.primary,
            }
          }}
        >
          <CachedIcon fontSize="small" />
        </IconButton>
      </span>
    </Tooltip>
  );
};

const ProgressBar = ({ visible, icon: Icon }) => {

  const [tooltipOpen, setTooltipOpen] = useState(true);
  const theme = useTheme();
  const tooltipTimeoutRef = useRef(null);

  const { desiredRefreshInterval,
    testCount,
    setTestCount,
    setResetProgress,
    progress,
    setProgress,
    sessionWPMscores,
    setSessionWPMscores,
    WPM
  } = useContext(MainContentContext);
  const { randomPhase, randomDuration } = useContext(SuperMainContentContext);
  const { isFocused, setIsFocused, isWebsiteReady } = useContext(AppContext);


  useEffect(() => {
    if (desiredRefreshInterval === 0) {
      // In infinite mode, stay in random phase for randomDuration tests
      randomPhase.current = testCount < randomDuration;
    } else {
      // In finite mode, use modulo to cycle between phases
      const cyclePosition = testCount % desiredRefreshInterval;
      randomPhase.current = cyclePosition < randomDuration;
    }

    console.log('Phase calculation:', {
      testCount,
      desiredRefreshInterval,
      randomDuration,
      isRandomPhase: randomPhase.current,
      mode: desiredRefreshInterval === 0 ? 'infinite' : 'finite'
    });

    console.log('testCount:',
      testCount,
      'desiredRefreshInterval:',
      desiredRefreshInterval,
      'randomDuration:',
      randomDuration,
      'randomPhase:',
      randomPhase.current,
      'progress:',
      progress,
      'position:',
      position,
      'random Width: ',
      randomWidth,
      'nonRandomWidth:',
      nonRandomWidth,
      'singleRandomTestWidth:',
      singleRandomTestWidth,
      'singleTargetedTestWidth:',
      singleTargetedTestWidth);
  }, [testCount, desiredRefreshInterval, randomDuration]);

  const refreshProgress = () => {
    setIsFocused(true);
    if (testCount !== 0) {
      setTestCount(0);
      setResetProgress(true);
    }
  };

  //I want to put the WPM into the sessionWPMscores array on every progress increment
  useEffect(() => {
    if (WPM > 0) {
      setSessionWPMscores([...sessionWPMscores, WPM]);
    }
  }, [progress]);

  //remove 'desiredRefreshInterval' amount of WPM scores from the array if the array length exceeds 'desiredRefreshInterval'
  useEffect(() => {
    if (sessionWPMscores.length > desiredRefreshInterval) {
      
    }
  }, [sessionWPMscores]);


  // Determine progress based on test count
  useEffect(() => {
    if (desiredRefreshInterval > 0) {
      setProgress(testCount % desiredRefreshInterval);
    } else {
      setProgress(testCount);
    }
  }, [testCount, desiredRefreshInterval]);

  useEffect(() => {
    setTooltipOpen(false);
    if (tooltipTimeoutRef.current) {
      clearTimeout(tooltipTimeoutRef.current);
    }
    tooltipTimeoutRef.current = setTimeout(() => {
      setTooltipOpen(true);
    }, 900);
    return () => {
      if (tooltipTimeoutRef.current) {
        clearTimeout(tooltipTimeoutRef.current);
      }
    };
  }, [progress]);

  //TODO memoize this
  // Calculate widths for the progress bar sections
  const MIN_RANDOM_WIDTH = 18; // Minimum width percentage for random section
  const MIN_NON_RANDOM_WIDTH = 18; // Minimum width percentage for non-random section
  let randomWidth = MIN_RANDOM_WIDTH;
  let nonRandomWidth = 100 - randomWidth; //I later change this so what is the point of setting it here?
  
  if ((randomDuration > 0) && (desiredRefreshInterval > 0)) {
    randomWidth = Math.max(MIN_RANDOM_WIDTH, (randomDuration / desiredRefreshInterval) * 100);
  }
  if (randomDuration === 0) {
    randomWidth = 0;
  }
  if ((desiredRefreshInterval === 0) && (randomDuration > 0)) {
    randomWidth = MIN_RANDOM_WIDTH;
  }
  // nonRandomWidth = 100 - randomWidth;

  if ((desiredRefreshInterval - randomDuration > 0) || (desiredRefreshInterval === 0)) {
    nonRandomWidth = Math.max(MIN_NON_RANDOM_WIDTH, 100 - randomWidth);
  }
  else {
    nonRandomWidth = 0;
  }

  let singleRandomTestWidth;
  if (randomDuration === 0) {
    singleRandomTestWidth = 0;
  } 
  else {
    singleRandomTestWidth = randomWidth / randomDuration;
  }
  let singleTargetedTestWidth;
  
  if (desiredRefreshInterval === 0) {
    singleTargetedTestWidth = 0;
  }
  else {
    singleTargetedTestWidth = nonRandomWidth / (desiredRefreshInterval - randomDuration);
  }

  // Calculate progress percentage
  // 0 = start, 100 = end
let position;
if (desiredRefreshInterval === 0) {
  if (randomPhase.current) {
    // During random analysis, show progress within random section
    position = (progress + 0.5) * singleRandomTestWidth;
  } else {
    // During targeted practice, stay centered
    position = 50;
  }
} else {
  // Normal progress calculation for non-infinite mode
  if (randomPhase.current) {
    position = (progress + 0.5) * singleRandomTestWidth;
  } else {
    let targetedProgress = progress - randomDuration;
    position = randomWidth + (targetedProgress + 0.5) * singleTargetedTestWidth;
  }
}



  const tooltipText = desiredRefreshInterval === 0 ?
    `Test: ${progress + 1}/∞ in session.${progress === 0 ?
      ' Start typing the words you see in the box.' : ''}`
    : `Test: ${progress + 1}/${desiredRefreshInterval} in session.${progress === 0 ?
      ' Start typing the words you see in the box.' : ''}`;

  return (
    <>
      <Box
        data-tour="progress-bar"
        sx={{
          position: 'relative',
          display: 'flex',
          alignItems: 'center',
          width: '100%',
          mb: "1em",
          mt: "3em"
        }}
      >
        <Box
          sx={{
            flexGrow: 1,
            position: 'relative',
            height: '1em',
            display: 'flex'
          }}>

          {/*                     
     <>
       <Box
         data-tour="progress-bar"
         sx={{
           position: 'relative',
           width: '100%',
           height: '1em',
           display: 'flex',
           mb: "1em",
           mt: "3em"
         }}> */}


          {randomDuration > 0 &&
            <Box
              data-tour="random-progress"
              sx={{
                backgroundColor: alpha(theme.palette.info.light, 0.2),
                borderRadius: '20px 0 0 20px',
                height: "100%",
                width: `${randomWidth}%`,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <VscGraphScatter />
              {randomWidth > 15 && (
                <Typography variant="body2" sx={{ ml: 1 }}>Random Analysis</Typography>
              )}
            </Box>
          }
          {((randomDuration < desiredRefreshInterval) || (desiredRefreshInterval === 0)) &&
            <Box
              data-tour="targeted-progress"
              sx={{
                backgroundColor: alpha(theme.palette.success.light, 0.2),
                borderRadius: randomDuration > 0 ? '0 20px 20px 0' : '20px',
                height: "100%",
                width: `${nonRandomWidth}%`,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {desiredRefreshInterval === 0 && <AllInclusiveIcon />}
              <TimelineIcon />
              {nonRandomWidth > 15 && (
                <Typography variant="body2" sx={{ ml: 1 }}>Targeted Practice</Typography>
              )}
            </Box>
          }
          <AvatarIconWrapper
            data-tour="progress-bar-avatar"
            icon={Icon}
            progress={position}
            tooltipText={tooltipText}
            tooltipOpen={tooltipOpen && isWebsiteReady}
            isFocused={isFocused}
            visible={visible}
          />
        </Box>
        <RefreshButton
          onClick={refreshProgress}
          disabled={testCount === 0}
        />
      </Box>
    </>
  );
};

export default ProgressBar;