import { useEffect, useRef } from 'react';

const useCaretPosition = (position, isLoading, isFocused, shouldBlink) => {
  const caretRef = useRef(null);

  useEffect(() => {
    if (isLoading || !isFocused || !position) return;

    const updateCaretPosition = () => {
      if (caretRef.current) {
        caretRef.current.style.transform = `translate(${position.left - 1}px, ${position.top - 1}px)`;
        caretRef.current.style.width = `${position.width}px`;
        caretRef.current.style.height = `${position.height - 2}px`;
        caretRef.current.style.opacity = '1';
      }
    };

    requestAnimationFrame(updateCaretPosition);
  }, [position, isLoading, isFocused]);

  return caretRef;
};


export default useCaretPosition;
