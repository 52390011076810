import React from 'react';
import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useCaretPosition from './useCaretPosition';

const Caret = ({ position, isLoading, isFocused, shouldBlink = false }) => {
  const theme = useTheme();
  const caretRef = useCaretPosition(position, isLoading, isFocused, shouldBlink);

  if (!isFocused) {
    return null;
  }

  return (
    <Box
      ref={caretRef}
      data-caret
      sx={{
        position: 'absolute',
        backgroundColor: theme.palette.action.disabledBackground,
        zIndex: 1,
        transition: 'all 0.1s ease-out',
        opacity: 0,
        borderRadius: '2px',
        left: 0,
        top: 0,
        pointerEvents: 'none',
        animation: shouldBlink ? 'blink 1.0s infinite' : 'none',
        '@keyframes blink': {
          '0%': { opacity: 0 },
          '50%': { opacity: 1 },
          '100%': { opacity: 0 },
        },
      }}
    />
  );
};

export { Caret };