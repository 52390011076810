import React from 'react';
import { Box } from '@mui/material';
import { MemoizedSlidingLetter } from './SlidingLetter';

// Claude instructions:
// This is a placeholder I made just in case the "Word" component is 
// somehow limiting to the functionality that we are trying to achieve 
// with "SlidingDisplay"
// If needed, fill this with code. Otherwise, just don't use it.
const SlidingWord = ({ 
  word,
  startIndex,
  currentIndex,
  userInput,
  setCorrectness
}) => {
  return (
    <Box sx={{ display: 'flex' }}>
      {word.split('').map((letter, index) => {
        const absoluteIndex = startIndex + index;
        let correctness = null;

        if (absoluteIndex < currentIndex) {
          correctness = setCorrectness(absoluteIndex);
        }

        return (
          <MemoizedSlidingLetter
            key={absoluteIndex}
            letter={letter}
            isSpace={letter === ' '}
            correctness={correctness}
            index={absoluteIndex}
          />
        );
      })}
    </Box>
  );
};

const MemoizedSlidingWord = React.memo(SlidingWord);

export { SlidingWord, MemoizedSlidingWord };