import React, { useContext } from 'react';
import { Box, Slider } from '@mui/material'

import { SliderWrapper } from './SliderWrapper'
import { MainContentContext, } from '../contexts/MainContentContext'
import { SuperMainContentContext } from '../contexts/SuperMainContext.jsx'
import { useTheme } from '@mui/material/styles';
import { historyToolTip, randomnessTooltip,
  refreshIntervalTooltip, randomDurationToolTip, wordsHistoryToolTip, wordRepetitionToolTip
} from '../utils/toolTipTexts.js'



//TODO add a warning if all templates are empty
function InnerDashboard() {
  const weaknessOptions = ["Letters", "Bigrams", "Trigrams", "Words", "Spacegrams"]
  const theme = useTheme();

  const {
    slowPatternTemplates, setSlowPatternTemplates,
    inaccuratePatternTemplates, setInaccuratePatternTemplates,
    desiredWeaknessHistory, setDesiredWeaknessHistory, desiredRefreshInterval,
    setDesiredRefreshInterval, freeze, setFreeze, 
     desiredWordsHistory, setDesiredWordsHistory,
  } = useContext(MainContentContext);

  const {
    desiredRandomness, setDesiredRandomness, letterLimit, setLetterLimit,
    punctuation, setPunctuation, randomDuration, setRandomDuration,
    wordRepetition, setWordRepetition
  } = useContext(SuperMainContentContext);



  const handleWordRepetitionSlider = (event, newValue) => {
    setWordRepetition(newValue);
  }

  const handleWeaknessHistorySlider = (event, newValue) => {
    console.log(newValue)
    setDesiredWeaknessHistory(newValue);
  };

  const handleWordsHistorySlider = (event, newValue) => {
    console.log(newValue)
    setDesiredWordsHistory(newValue);
  };
 
  const handleRandomnessSlider = (event, newValue) => {
    console.log(newValue)
    setDesiredRandomness(newValue);
  };

  const handleRefreshIntervalSlider = (event, newValue) => {
    console.log(newValue)
    if (newValue !== 0 && newValue <= randomDuration) {
      // setDesiredRefreshInterval(randomDuration + 1);
      setDesiredRefreshInterval(randomDuration);
    }
    else {
      setDesiredRefreshInterval(newValue);
    }
  };

  const handleRandomDurationSlider = (event, newValue) => {
    setRandomDuration(newValue);
    if (desiredRefreshInterval !== 0 && desiredRefreshInterval <= newValue) {
      // setDesiredRefreshInterval(newValue + 1);
      setDesiredRefreshInterval(newValue);
    }
  }

  return (
    <>
      {/* <Divider textAlign='center' sx={{
        typography: 'p',
        textAlign: 'center',
        borderRadius: 2,
        m: '1em'
      }}>
        <Card
          sx={{
            borderRadius: 20,
            // bgcolor: theme.palette.background.default,
            bgcolor: theme.palette.background.paper,
            color: theme.palette.text.secondary,
          }}
          variant="outlined" >
          <Stack direction="row">
            <Box sx={{ display: 'flex', alignItems: 'center', mx: '10px' }}>
              <TuneIcon size={24} />
            </Box>
            <Typography variant="p" sx={{ pr: "30px" }}  >
              {"Advanced Settings"}
            </Typography>
          </Stack>
        </Card>
      </Divider> */}


      <Box
        data-tour="inner-dashboard"
        sx={{
          ...theme.customStyles.patternsBox,
          backgroundColor: theme.palette.background.paper,
          borderColor: theme.palette.divider,
          // my: '1.5em',
        }}
      >
      
        <SliderWrapper
          label={`Weakness History: ${desiredWeaknessHistory}`}
          tooltipTitle={historyToolTip}
          tooltipPlacement="left-start"
        >
          <Slider
            color="secondary"
            value={desiredWeaknessHistory}
            onChange={handleWeaknessHistorySlider}
            step={1}
            marks={[
              { value: 1, label: '1' },
              { value: 6, label: '6' },
              { value: 11, label: '11' }
            ]}
            min={1}
            max={11}
            sx={{
              width: "80%", mt: 0, ml: "1em",
              '& .MuiSlider-markLabel': {
                color: 'text.primary',
              },
            }} />
        </SliderWrapper>

        <SliderWrapper
          label={`Words History: ${desiredWordsHistory}`}
          tooltipTitle={wordsHistoryToolTip}
          tooltipPlacement="left-start"
        >
          <Slider
            color="secondary"
            value={desiredWordsHistory}
            onChange={handleWordsHistorySlider}
            step={1}
            marks={[
              { value: 0, label: '0' },
              { value: 5, label: '5' },
              { value: 10, label: '10' }
            ]}
            min={0}
            max={10}
            sx={{
              width: "80%", mt: 0, ml: "1em",
              '& .MuiSlider-markLabel': {
                color: 'text.primary',
              },
            }}
          />
        </SliderWrapper>

        <SliderWrapper
          label={`Word Repetition: ${wordRepetition}`}
          tooltipTitle={wordRepetitionToolTip}
          tooltipPlacement="left-start"
        >
          <Slider
            color="secondary"
            value={wordRepetition}
            onChange={handleWordRepetitionSlider}
            step={1}
            marks={[
              { value: 1, label: '1' },
              { value: 2, label: '2' },
              { value: 3, label: '3' },
              { value: 4, label: '4' }
            ]}
            min={1}
            max={4}
            sx={{
              width: "80%", mt: 0, ml: "1em",
              '& .MuiSlider-markLabel': {
                color: 'text.primary',
              },
            }}
          />
        </SliderWrapper>

        <SliderWrapper
          label={`Randomness: ${desiredRandomness}%`}
          tooltipTitle={randomnessTooltip}
          tooltipPlacement="left-start"
        >
          <Slider
            color="secondary"
            value={desiredRandomness}
            onChange={handleRandomnessSlider}
            step={5}
            marks={[
              { value: 0, label: '0%' },
              { value: 25, label: '25%' },
              { value: 50, label: '50%' },
              { value: 75, label: '75%' },
              { value: 100, label: '100%' }
            ]}
            max={50}
            sx={{
              width: "80%", mt: 0, ml: "1em",
              '& .MuiSlider-markLabel': {
                color: 'text.primary',
              },
            }} />
        </SliderWrapper>

        {/* <SliderWrapper
          label={`Letter Limit: ${letterLimit}`}
          tooltipTitle={letterLimitToolTip}
          tooltipPlacement="left-start"
        >
          <Slider
            color="secondary"
            value={letterLimit}
            onChange={handleLetterLimitSlider}
            step={1}
            marks={[
              { value: 5, label: '5' },
              { value: 15, label: '15' },
              { value: defaultLetterLimit, label: `${defaultLetterLimit}` }
            ]}
            min={5}
            max={defaultLetterLimit}
            sx={{
              width: "80%", mt: 0, ml: "1em",
              '& .MuiSlider-markLabel': {
                color: 'text.primary',
              },
            }} />
        </SliderWrapper> */}

        <SliderWrapper
          label={`Tests in a session: ${desiredRefreshInterval === 0 ? '∞' : desiredRefreshInterval}`}
          tooltipTitle={refreshIntervalTooltip}
          tooltipPlacement="left-start"
        >
          <Slider
            color="secondary"
            value={desiredRefreshInterval}
            onChange={handleRefreshIntervalSlider}
            step={1}
            marks={[
              { value: 0, label: '∞' },
              { value: 25, label: '25' },
              // { value: 50, label: '50' },
              // { value: 100, label: '100' }
            ]}
            // min={randomDuration}
            max={25}
            sx={{
              width: "80%", mt: 0, ml: "1em",
              '& .MuiSlider-markLabel': {
                color: 'text.primary',
              },
            }} />
        </SliderWrapper>
      
        {/* <Stack direction="row" spacing={2} sx={{ml:"1em"}}>
      <Typography>
        {`Next Refresh: ${desiredRefreshInterval === 0 ? 'Never' : (desiredRefreshInterval - testCount % desiredRefreshInterval)}`}
      </Typography>
      <Typography>{`Test Count: ${testCount}`}</Typography>
      </Stack> */}

        <SliderWrapper
          label={`Random Duration: ${randomDuration}`}
          tooltipTitle={randomDurationToolTip}
          tooltipPlacement="left-start"
        >
          <Slider
            color="secondary"
            value={randomDuration}
            onChange={handleRandomDurationSlider}
            step={1}
            marks={[
              { value: 1, label: '1' },
              { value: 6, label: '6' },
              { value: 11, label: '11' }
            ]}
            min={0}
            max={11}
            sx={{
              width: "80%", mt: 0, ml: "1em",
              // '& .MuiSlider-markLabel': {
              //   color: 'text.primary',
              // },
            }} />
        </SliderWrapper>
      </Box>
    </>
  );

}

export { InnerDashboard }